import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import updateImg from "../../src/assets/browser.png";
import submitImg from "../../src/assets/upload.png";
import { apiErrors } from "../helpers/apiErrors";
import { SignaturePad } from "../components/reusable/sigPad";
import PackSignBanner from "../components/packSignBanner";
import Previewer from "../components/reusable/previewer";
import OptionSelection from "../components/optionSelection";
import CompleteApplicationUpload from "../components/completeApplicationUpload";
import CompleteApplicationSign from "../components/completeApplicationSign";
import ClaimForm from "../components/claimForm/main";
import MissoldEnergy from "../components/pageComponents/misSoldEnergy";
import HowCanWeHelp from "../components/pageComponents/howCanWeHelp";
import GreenBarDivider from "../components/pageComponents/greenBarDivider";
import ClientNumbers from "../components/pageComponents/clientNumbers";
import HereToHelp from "../components/pageComponents/hereToHelp";
import WarningSigns from "../components/pageComponents/warningSigns";
import SecretCommission from "../components/pageComponents/secretCost";
import Faqs from "../components/pageComponents/faqs";
import Hero from "../components/pageComponents/hero";

function MakeClaim() {
    const [resumeClaimData, setResumeClaimData] = useState<any>();
    const { token } = useParams();
    const [paramsObj, setParamsObj] = useState<any>({});
    const queryParameters = new URLSearchParams(window.location.href);

    const [claimResumed, setClaimResumed] = useState(true);
    const [requestingClaim, setRequestingClaim] = useState(true);

    useEffect(() => {
        if (!requestingClaim) {
            axios
                .post("", {})
                .then((response) => {
                    const resumeData = response.data;
                    console.log("resumeData:", resumeData);
                    if (resumeData.success) {
                        setResumeClaimData(resumeData);
                        setClaimResumed(true);
                    } else {
                        throw new Error("failed....");
                    }
                    // Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    Loading.remove();
                    if (e.response) {
                        const serverError = e.response.data;
                        apiErrors(serverError);
                    } else {
                        apiErrors("unknown");
                    }
                });
        }
    }, [requestingClaim]);

    return (
        <div className={`${styles.energyClaimFormContainer}`}>
            <Hero />
            {/* <ClaimForm /> */}
            <GreenBarDivider />
            <SecretCommission />
            <ClientNumbers />
            <MissoldEnergy />
            <WarningSigns />
            <HowCanWeHelp />
            <HereToHelp />
            <Faqs />
        </div>
    );
}

export default MakeClaim;
