import styles from "./styles.module.scss";
import { CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import tick from "../../../assets/accept.png";

function HereToHelp() {
    return (
        <div className={`${styles.hereToHelpContainer} ${responsiveStyles.hereToHelpContainer}`}>
            <Wrapper>
                <div className={styles.titleContainer}>
                    <h2>We're here to help you</h2>

                    <p>
                        <img src={tick} />
                        We understand it's a difficult time for people and businesses up and down the country. Energy prices have surged, with many
                        businesses looking at a fourfold increase: so every penny matters.
                    </p>
                    <p>
                        <img src={tick} />
                        Your Business Energy Refund is here to identify your claim status, provide expert legal advice and support in delivering your
                        case, and always looks to uncover and recover the money you might be overpaying in mis-sold business energy contract
                        commissions.
                    </p>
                    <p>
                        <img src={tick} />
                        Our aim is to help businesses, regardless of size, conserve their energy bills and determine just how much they maybe being
                        overcharged on.
                    </p>
                    <CtaButton text="CHECK MY ENERGY DEAL" />
                </div>
            </Wrapper>
        </div>
    );
}

export default HereToHelp;
