
import { Report } from "notiflix/build/notiflix-report-aio";
import { Loading } from "notiflix";
const fileTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf", 'image/heic'];

export const validFile = (files: any, setFileObject: any, defaultImg: string) => {
    if (files.length < 1) {
        Loading.remove();
        setFileObject(defaultImg);
        return false;
    }
    const file = files[0];

    if (file.size > 10000000) { // 10MB
        Loading.remove();
        Report.failure("File too big.", "Your file is bigger than 10MB, please choose a smaller file.", "ok");
        setFileObject(defaultImg);
        return false;
    }

    if (!fileTypes.some((elem) => elem == file.type)) {
        Loading.remove();
        Report.failure("File type error.", "Your file is not of type heic, heif, jpeg, jpg, png, pdf", "ok");
        setFileObject(defaultImg);
        return false;
    }
    // console.log('file OK ....');
    return true
}