import styles from "./styles.module.scss";
import { Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import upwardGraph from "../../../assets/hero.jpg";
import ClaimForm from "../../claimForm/main";
function Hero() {
    return (
        <div className={`${styles.hero} ${responsiveStyles.hero}  `}>
            <Wrapper>
                <div className={`${styles.heroContent} ${responsiveStyles.heroContent}`}>
                    <span>Complete your claim in 2 minutes</span>
                    <h1>Has Your Business Overpaid On Energy Bills?</h1>
                    <p>
                        Your business could be owed thousands in unclaimed energy refunds. <br />
                    </p>
                    <ClaimForm />
                </div>
            </Wrapper>
        </div>
    );
}

export default Hero;
