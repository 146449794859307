import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import updateImg from "../../src/assets/browser.png";
import submitImg from "../../src/assets/upload.png";
import { apiErrors } from "../helpers/apiErrors";
import { SignaturePad } from "../components/reusable/sigPad";
import PackSignBanner from "../components/packSignBanner";
import Previewer from "../components/reusable/previewer";
import OptionSelection from "../components/optionSelection";
import CompleteApplicationUpload from "../components/completeApplicationUpload";
import CompleteApplicationSign from "../components/completeApplicationSign";

function ContactFusionSign() {
    const [urlData, setUrlData] = useState<any>();
    const { token } = useParams();
    const [paramsObj, setParamsObj] = useState<any>({});
    const queryParameters = new URLSearchParams(window.location.href);

    const [receivedUrls, setReceivedUrls] = useState(false);
    const [requestingUrls, setRequestingUrls] = useState(false);

    useEffect(() => {
        if (!requestingUrls) {
            var textArea = document.createElement("textarea");
            textArea.innerHTML = window.location.href;
            const queryParameters = new URLSearchParams(textArea.value.split("?")[1]);
            let error: boolean = false;
            setRequestingUrls(true);
            let po: any = {};
            for (const [key, value] of queryParameters.entries()) {
                po[key] = value;
                if (value == "") error = true;
            }
            po.contactId = token;
            if (error) {
                Report.failure("Error", "An error has occurred, please submit your form again.", "okay", () => {});
                return;
            }
            localStorage.setItem("name", `${po.firstname} ${po.lastname}`);
            setParamsObj(po);
            let payload = po;
            const endpoint = `${process.env.REACT_APP_CONTACTFUSION_LEAD_URL}`;
            // console.log("PARAMETERS.....", po);
            // console.log("payload: ", payload);
            // console.log("url to post to: ", endpoint);

            axios
                .post(endpoint, payload)
                .then((response) => {
                    const signatureData = response.data;
                    console.log("signatureData:", signatureData);
                    if (signatureData.success) {
                        setUrlData(signatureData);
                        setReceivedUrls(true);
                    } else {
                        throw new Error("failed....");
                    }
                    // Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    Loading.remove();
                    if (e.response) {
                        const serverError = e.response.data;
                        apiErrors(serverError);
                    } else {
                        apiErrors("unknown");
                    }
                });
        }
    }, [requestingUrls]);

    return (
        <div className={`${styles.fushionContent}`}>
            <Wrapper>
                <CompleteApplicationSign loaded={receivedUrls} urlData={urlData} data={paramsObj} />
            </Wrapper>
        </div>
    );
}

export default ContactFusionSign;
