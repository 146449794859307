import styles from "../globals/pages.module.scss";
import pageStyles from "./styles.module.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import updateImg from "../../src/assets/browser.png";
import submitImg from "../../src/assets/upload.png";
import { apiErrors } from "../helpers/apiErrors";
import { SignaturePad } from "../components/reusable/sigPad";
import PackSignBanner from "../components/packSignBanner";
import Previewer from "../components/reusable/previewer";

function IntroducedSign() {
    const [claimData, setClaimData] = useState<any>();
    const [retrievedClaim, setRetrievedClaim] = useState(false);
    const { token } = useParams();
    useEffect(() => {
        console.log(token);
        if (!retrievedClaim) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const signatureData = response.data;
                    console.log("signatureData:", signatureData);
                    localStorage.setItem("name", `${signatureData.firstname} ${signatureData.lastname}`);
                    if (signatureData != null) {
                        setClaimData(signatureData);
                        setRetrievedClaim(true);
                    }
                    Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    if (e.response) {
                        const serverError = e.response.data;
                        Loading.remove();
                        apiErrors(serverError);
                    } else {
                        apiErrors("unknown");
                    }
                });
        }
    }, []);

    const completeClaim = () => {
        console.log("documents signed....");
        Report.success(
            "Documents Signed",
            "Thank you for signing all your documents. We will update you on your claim in due course.",
            "Okay",
            () => {
                window.location.href = "https://google.com";
            }
        );
    };
    return (
        <div className={`${styles.pageContent}`}>
            <PackSignBanner />
            <Wrapper>
                <div className={styles.pageBody}>
                    <h2>Sign Your Document Pack</h2>
                    <p>
                        The Business Energy Refunds team have prepared your claim documents. To progress your claim you are required to review and
                        sign the documents listed below.
                        <br />
                        <br />
                        Your document pack includes:
                    </p>
                    <ul>
                        <li>Terms of Business</li>
                        <li>Authority of Disclosure</li>
                        <li>Conditonal Fee Agreement</li>
                    </ul>
                    <p>Once these actions are completed, we will be in touch with an update on your claim.</p>
                </div>
                <div className={styles.previews}>
                    {claimData != null ? (
                        <>
                            <div className={styles.docContainer}>
                                <Previewer url={claimData.termsUrl} />
                                <p>
                                    <a href={claimData.termsUrl} target="_blank">
                                        Download
                                    </a>
                                </p>
                            </div>
                            <div className={styles.docContainer}>
                                <Previewer url={claimData.disclosureAuthorityUrl} />
                                <p>
                                    <a href={claimData.disclosureAuthorityUrl} target="_blank">
                                        Download
                                    </a>
                                </p>
                            </div>
                            <div className={styles.docContainer}>
                                <Previewer url={claimData.cfaUrl} />
                                <p>
                                    <a href={claimData.cfaUrl} target="_blank">
                                        Download
                                    </a>
                                </p>
                            </div>
                            {claimData.disclosureAuthorityUrl2 ? (
                                <>
                                    <div className={styles.docContainer}>
                                        <Previewer url={claimData.disclosureAuthorityUrl2} />
                                        <p>
                                            <a href={claimData.disclosureAuthorityUrl2} target="_blank">
                                                Download
                                            </a>
                                        </p>
                                    </div>{" "}
                                    <div className={styles.docContainer}>
                                        <Previewer url={claimData.cfaUrl2} />
                                        <p>
                                            <a href={claimData.cfaUrl2} target="_blank">
                                                Download
                                            </a>
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </div>
                <SignaturePad additionalAction={completeClaim} extraPayload={{ type: "introducerSign" }} />
            </Wrapper>
        </div>
    );
}

export default IntroducedSign;
