export const supplierData = [
    { value: "British Gas", label: "British Gas" },
    { value: "EDF", label: "EDF" },
    { value: "EON", label: "EON" },
    { value: "Npower", label: "Npower (Part of EON Nov 2019 onwards as EON Next)" },
    { value: "Octopus", label: "Octopus" },
    { value: "OVO ", label: "OVO (SSE until December 2019)" },
    { value: "Scottish Power", label: "Scottish Power" },
    { value: 'Drax Power', label: 'Drax Power' },
    { value: 'Shell Energy', label: 'Shell Energy' },
    { value: 'Total Energies', label: 'Total Energies' },
    { value: 'Engie', label: 'Engie' },
    { value: 'Corona Energy', label: 'Corona Energy' },
    { value: "Smartest Energy", label: "Smartest Energy" },
    { value: "Yu Energy", label: "Yu Energy" },
    // { value: "CUSTOM SUPPLIER", label: "Custom Supplier" },
]; 