import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import { positionData } from "../../../../data/positions";
import { contactPreferencesData } from "../../../../data/contactPreferences";
import { FormCard } from "../../../reusable";

import { FormPartInterface, SaveValueInterface } from "../../../../interfaces";

export const CompleteForm = (props: FormPartInterface) => {
    const { isActive } = props;

    const [currentStep, setCurrentStep] = useState(1);
    const getCurrentStep = () => {
        return isActive == "complete" ? (
            <FormCard assignPostStep={() => {}} step={currentStep}>
                <div className={styles.complete}>
                    <h1>Thank you.</h1>
                    <p>
                        Your claim has been completed successfully.
                        <br />A member of the energy refunds team will be in touch shortly to discuss your claim.
                    </p>
                </div>
            </FormCard>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
