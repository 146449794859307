import s from "./styles.module.scss";

export interface buttonContainerProps {
    children: React.ReactNode;
}

export const ButtonContainer = (props: buttonContainerProps) => {
    const { children } = props;
    return <div className={s.sidebyside}>{children}</div>;
};
