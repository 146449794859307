import React, { useContext, useState } from "react";

export interface contextProps {
    children: React.ReactNode;
}

const ClaimContext = React.createContext<any>("");
const ClaimUpdateContext = React.createContext<any>("");

export function useClaimData() {
    return useContext(ClaimContext);
}

export function useUpdateClaimData() {
    return useContext(ClaimUpdateContext);
}

export function ClaimProvider({ children }: contextProps) {
    const [claimData, setClaimData] = useState<any>();

    function updateClaim(cd: any) {
        setClaimData(cd);
    }
    return (
        <ClaimContext.Provider value={claimData}>
            <ClaimUpdateContext.Provider value={updateClaim}>{children}</ClaimUpdateContext.Provider>
        </ClaimContext.Provider>
    );
}
