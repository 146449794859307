import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { CtaButton, Wrapper } from "../../reusable";
import secrets from "../../../assets/secrets-img.png";

function Faqs() {
    const toggleFaq = (obj: any) => {
        let elem = obj.querySelector("div:last-of-type");
        if (elem.style.height == "auto") {
            elem.style.height = "";
        } else {
            elem.style.height = "auto";
        }
    };
    return (
        <div className={`${styles.faq} ${responsiveStyles.faq}`}>
            <Wrapper>
                <h2>Frequently Asked Questions</h2>
                <div className={`${styles.faqContainer} ${responsiveStyles.faqContainer}`}>
                    <div className={`${styles.faqItem} ${responsiveStyles.faqItem}`} onClick={(e) => toggleFaq(e.target)}>
                        <div>
                            <p>Are business energy refunds anything like PPI? Why can't I just ask my broker about their commission earnings?</p>
                        </div>
                        <div>
                            <p>
                                There are notable similarities between mis-sold business energy deals and mis-sold financial agreements like PPI. The
                                key similarity is that unreliable brokers and suppliers failed to disclose how much commission was attached to your
                                agreement. You can freely ask your broker about the commission, but as the energy broker industry is currently
                                unregulated, they have no obligation to openly declare just how much money they are earning off commissions attached
                                to the deal they sold you.
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.faqItem} ${responsiveStyles.faqItem}`} onClick={(e) => toggleFaq(e.target)}>
                        <div>
                            <p>Will this cost me anything?</p>
                        </div>
                        <div>
                            <p>
                                We take your case on in anticipation of a victorious outcome, and all our cases are conducted on a no-win, no-fee
                                basis: this means that unless you're successful and receive financial redress, we don't earn a penny for our services.
                                In the event your claim is successful, we will deduct our fee (set out clearly and explained to you in full when you
                                begin your claim) from the compensation awarded. This means that you won't owe us a thing throughout the process of
                                your claim.{" "}
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.faqItem} ${responsiveStyles.faqItem}`} onClick={(e) => toggleFaq(e.target)}>
                        <div>
                            <p>What is a TPI?</p>
                        </div>
                        <div>
                            <p>
                                A ‘Third Party Intermediary', otherwise known as a TPI, is the name given to an energy broker that acts as a middleman
                                between businesses and energy companies. It's their job to find the best business energy deal for you. But recent
                                findings show they may be adding extra, undisclosed commissions to your packages for their own benefit…{" "}
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.faqItem} ${responsiveStyles.faqItem}`} onClick={(e) => toggleFaq(e.target)}>
                        <div>
                            <p>I have a good relationship with my broker, will a claim affect this?</p>
                        </div>
                        <div>
                            <p>
                                Many of our past and existing clients have a strong relationship with their energy broker, and we understand that this
                                sense of trust is what got you your current energy deal. But it's often signs of trust and promises of a fantastic
                                business energy deal that are a warning sign of a mis-sold agreement. If we can strike a successful claim for you,
                                it's likely the broker will offer to remove their commissions and lower your rate, or they may offer to cancel your
                                existing contract: we will always ask for your permission to have any such changes applied to your account and ensure
                                your business won't go without energy.
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.faqItem} ${responsiveStyles.faqItem}`} onClick={(e) => toggleFaq(e.target)}>
                        <div>
                            <p>How can a legal challenge regarding hidden commissions be successful?</p>
                        </div>
                        <div>
                            <p>
                                Legal challenges can argue that the hidden commissions breached duties owed to the customer and could legally be
                                construed as a bribe, potentially entitling their clients to compensation.{" "}
                            </p>
                        </div>
                    </div>
                </div>
                <CtaButton text="CHECK MY ENERGY DEAL" />
            </Wrapper>
        </div>
    );
}

export default Faqs;
