import styles from "./styles.module.scss";
import { CtaButton, Wrapper } from "../../../components/reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

function WarningSigns() {
    return (
        <div className={`${styles.centralContainer} ${responsiveStyles.centralContainer}  `}>
            <Wrapper>
                <div className={styles.titleContainer}>
                    <h2>The warning signs of being mis-sold</h2>
                </div>
                <div className={`${styles.twoColContainer} ${responsiveStyles.twoColContainer}`}>
                    <div className={styles.leftCol}>
                        <p>
                            Many business owners have often just accepted increased costs of their business energy deals, especially in the current
                            financial climate. But unbeknownst to thousands of them, they may have been mis-sold their plan by their energy broker.
                        </p>
                        <p>
                            Our expert team have identified the key warning signs to help you find out if you were potentially mis-sold your business
                            energy package.
                        </p>
                        <p>Are you and your business facing higher-than-projected energy costs?</p>
                    </div>
                    <div className={styles.rightCol}>
                        <ul>
                            <li>Were you assured everything is fine even as prices began to rise and your agreement surged in cost?</li>
                            <li>
                                Did you act on the advice you received and enter into a contract because of the claims made by a broker or third-party
                                intermediary?
                            </li>
                            <li>Did you suffer losses from energy bills higher than they were or should be?</li>
                        </ul>
                    </div>
                </div>
                <p className={`${styles.tagline} ${responsiveStyles.tagline}`}>
                    If you answered yes to any of these questions, then you might have been mis-sold your energy agreement.
                </p>
                <CtaButton text="CHECK MY ENERGY DEAL" />
            </Wrapper>
        </div>
    );
}

export default WarningSigns;
