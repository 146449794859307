import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { CloseIcon } from "../svgs";
interface modalInterface {
    children: React.ReactNode;
    modalState: boolean;
    previewModalAction: () => void;
}
function Modal({ children, modalState, previewModalAction }: modalInterface) {
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(modalState);
    }, [modalState]);

    const handleModal = (e: React.MouseEvent) => {
        previewModalAction();
    };

    return (
        <div className={`${styles.modalOuter} ${modalOpen ? styles.displayModal : ""}`}>
            <div className={`${styles.modalContainer} ${modalOpen ? styles.modalContainer : ""}`}>
                <button onClick={handleModal}>
                    <CloseIcon color="#FFFFFF" />
                </button>
                <div className={styles.modal}>{children}</div>
            </div>
        </div>
    );
}

export default Modal;
