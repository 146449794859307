import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "../components/layout/header/header";
import Footer from "../components/layout/footer/footer";
import ReactGA from "react-ga4";

declare global {
    interface Window {
        dataLayer: any;
    }
}
function Main() {
    let location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Page view" });
    }, [location]);

    return (
        <div className="main">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

export default Main;
