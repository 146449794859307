import React, { useEffect, useState } from "react";
// import styles from "./styles.module.scss";
import { FieldGroup, Field, SubmitButton, SelectButton, ButtonContainer, FormCard } from "../../../reusable";
import { Report } from "notiflix/build/notiflix-report-aio";

import { InputFieldText, InputFieldDate, InputFieldSelect } from "../../../reusable";
import { FormPartInterface, SaveValueInterface } from "../../../../interfaces";
import { companySearch } from "../../../../services/companySearch";
import { InputFieldAddressLookup } from "../../../reusable/inputFieldAddressLookup";

export const Business = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);

    const [entityType, setEntityType] = useState("Company");
    const [companyName, setCompanyName] = useState("");
    const [companies, setCompanies] = useState<any>([]);
    const [companiesCH, setCompaniesCH] = useState<any>([]);

    const [companyAddressText, setCompanyAddressText] = useState("");
    const [companyAddressOne, setCompanyAddressOne] = useState("");
    const [companyAddressTwo, setCompanyAddressTwo] = useState("");
    const [companyAddressLocality, setCompanyAddressLocality] = useState("");
    const [companyAddressRegion, setCompanyAddressRegion] = useState("");
    const [companyAddressPostcode, setCompanyAddressPostcode] = useState("");

    useEffect(() => {
        if (companiesCH.length > 0) {
            const companiesToSet = companiesCH.map((biz: any) => {
                return { label: biz.title, value: biz.company_number };
            });
            setCompanies(companiesToSet);
        }
    }, [companiesCH]);

    const entityTypeSelect = async (selection: SaveValueInterface) => {
        setEntityType(selection.value);
        let increment = 0;
        if (selection.value == "Company") {
            increment = 1;
        } else if (selection.value == "Charity Organisation") {
            increment = 4;
        } else if (selection.value == "Partnership") {
            increment = 5;
        } else if (selection.value == "Sole Trader") {
            increment = 6;
        }
        preSaveValueFunct({ field: "company_type", value: selection.value });
        setCurrentStep(currentStep + increment);
    };

    const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        await searchCompany({ field: "companySearch", value: newValue });
    };

    const searchCompany = async (searchData: { field: string; value: string }) => {
        try {
            const searchValue = searchData.value;
            if (searchValue.length > 2) {
                const companySearchResult = await companySearch(searchValue);
                const foundCompanies = companySearchResult.items;
                if (foundCompanies && foundCompanies.length > 0) {
                    setCompaniesCH(foundCompanies);
                }
            }
            return { success: true };
        } catch (error) {
            console.log("error:", error);
            return { success: false, error: error };
        }
    };

    const companySelect = async (selection: SaveValueInterface) => {
        // console.log("selected company.....", selection.value);
        const selectedCompany = companiesCH.find((biz: any) => biz.company_number == selection.value);

        if (selectedCompany.company_status != "active") {
            Report.failure("Unable to Progress", "Unfortunately we can only progress a claim with an active company.", "OK", () => {
                window.location.href = "https://google.com";
            });
            return;
        }

        let companyAddress = selectedCompany.address;
        preSaveValueFunct({ field: "company_name", value: selectedCompany.title });
        preSaveValueFunct({ field: "company_number", value: selectedCompany.company_number });
        preSaveValueFunct({ field: "company_address_one", value: companyAddress.address_line_1 });
        preSaveValueFunct({ field: "company_address_two", value: companyAddress?.address_line_2 });
        preSaveValueFunct({ field: "company_locality", value: companyAddress.locality });
        preSaveValueFunct({ field: "company_region", value: companyAddress.region });
        preSaveValueFunct({ field: "company_postcode", value: companyAddress.postal_code });
        preSaveValueFunct({ field: "company_country", value: companyAddress.country });
        setCurrentStep(currentStep + 1);
        // animateBeforeAssign("person");
    };

    const resetCompanyAddress = () => {
        setCompanyAddressOne("");
        setCompanyAddressTwo("");
        setCompanyAddressLocality("");
        setCompanyAddressRegion("");
        setCompanyAddressPostcode("");
    };
    useEffect(() => {
        if (companyAddressText.length > 0) {
            const addressArr = companyAddressText.split(", ");

            if (!addressArr.length) {
                resetCompanyAddress();
            }
            console.log("address array.....", addressArr);

            setCompanyAddressOne(addressArr[0]);
            preSaveValueFunct({ field: "company_address_one", value: addressArr[0] });

            if (addressArr.length > 4) {
                setCompanyAddressTwo(addressArr[1]);
                preSaveValueFunct({ field: "company_address_two", value: addressArr[1] });
            }

            if (addressArr.length > 3) {
                setCompanyAddressLocality(addressArr[addressArr.length - 3]);
                preSaveValueFunct({ field: "company_locality", value: addressArr[addressArr.length - 3] });
            }
            setCompanyAddressRegion(addressArr[addressArr.length - 2]);
            preSaveValueFunct({ field: "company_region", value: addressArr[addressArr.length - 2] });
            setCompanyAddressPostcode(addressArr[addressArr.length - 1]);
            preSaveValueFunct({ field: "company_postcode", value: addressArr[addressArr.length - 1] });
            preSaveValueFunct({ field: "company_country", value: "United Kingdom" });
        }
    }, [companyAddressText]);

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        // console.log("FIELD: ", dataToSave);
        saveValueFunct(dataToSave);
        if (dataToSave.field === "micro_company") {
            animateBeforeAssign("person");
        }

        const progressionFields = ["multisite_company", "company_type"];
        if (progressionFields.some((field) => field == dataToSave.field)) setCurrentStep(currentStep + 1);

        // setCurrentStep(currentStep + 1);
        return { success: true, error: "" };
    };

    const saveSoleTraderAddress = (dataToSave: SaveValueInterface) => {
        // console.log("FIELD: ", dataToSave);
        // resetCompanyAddress();
        setCompanyAddressText("");
        setCompanyAddressText(dataToSave.value);
        return { success: true, error: "" };
    };

    const businessSteps = [
        <Field fieldLabel="What type of entity would you like to make a business energy claim with?" key={1}>
            <SelectButton text="Limited Company" btnType="primary" onClick={() => entityTypeSelect({ field: "entityType", value: "Company" })} />
            <br />
            <SelectButton text="Sole Trader" btnType="primary" onClick={() => entityTypeSelect({ field: "entityType", value: "Sole Trader" })} />
            <br />
            <SelectButton
                text="Charity Organisation"
                btnType="primary"
                onClick={() => entityTypeSelect({ field: "entityType", value: "Charity Organisation" })}
            />
            <SelectButton text="Partnership" btnType="primary" onClick={() => entityTypeSelect({ field: "entityType", value: "Partnership" })} />
        </Field>,
        <Field fieldLabel="Start typing to search for your company" key={2}>
            {/* <InputFieldText fieldName="CompanySearch" saveValue={preSaveValueFunct}></InputFieldText> */}
            <input
                style={{
                    display: "block",
                    width: "800px",
                    maxWidth: "100%",
                    lineHeight: "1.6",
                    boxSizing: "border-box",
                    fontSize: "17px",
                    padding: "3px 5px",
                    borderRadius: "5px",
                    border: "1px solid #717171",
                }}
                type="text"
                name="companySearch"
                onChange={handleOnChange}
            />
            {companies.length > 0 ? (
                <>
                    <br />
                    <InputFieldSelect key={3} fieldName="companySelect" saveValue={companySelect} options={companies}></InputFieldSelect>{" "}
                    {/* <SubmitButton text="Submit" btnType="primary" onClick={() => setCurrentStep(currentStep + 1)} /> */}
                </>
            ) : (
                <></>
            )}
        </Field>,
        <Field fieldLabel="How many sites does your business trade from?" key={4}>
            <SubmitButton text="1 site" btnType="primary" onClick={() => preSaveValueFunct({ field: "multisite_company", value: "1" })} /> <br />
            <SubmitButton text="More than 1 site" btnType="primary" onClick={() => preSaveValueFunct({ field: "multisite_company", value: "0" })} />
        </Field>,
        <Field fieldLabel="Does your company turnover less than 2m pounds and have fewer than 10 employees?" key={5}>
            <SubmitButton text="Yes" btnType="primary" onClick={() => preSaveValueFunct({ field: "micro_company", value: "0" })} /> <br />
            <SubmitButton text="No" btnType="primary" onClick={() => preSaveValueFunct({ field: "micro_company", value: "1" })} />
        </Field>,
        // <Field fieldLabel={`${entityType} Is the business a micro business? (Business has less than 10 employees and less than €2 million turnover)`}>
        //     <ButtonContainer>
        //         <SelectButton
        //             text="Yes"
        //             btnType="primary"
        //             onClick={() => {
        //                 preSaveValueFunct({ field: "microbusiness", value: "yes" });
        //                 animateBeforeAssign("person");
        //             }}
        //         />
        //         <SelectButton
        //             text="No"
        //             btnType="primary"
        //             onClick={() => {
        //                 preSaveValueFunct({ field: "microbusiness", value: "no" });
        //                 animateBeforeAssign("person");
        //             }}
        //         />
        //     </ButtonContainer>
        // </Field>,
        <>
            <Field fieldLabel={`${entityType} Name`} key={11}>
                <InputFieldText fieldName="companyName" saveValue={preSaveValueFunct} />
            </Field>
            <Field fieldLabel={`${entityType} Trading Name`} key={12}>
                <InputFieldText fieldName="tradingName" saveValue={preSaveValueFunct} />
            </Field>
            <Field fieldLabel={`${entityType} Registration number`} key={13}>
                <InputFieldText fieldName="charityNumber" saveValue={preSaveValueFunct} />
            </Field>
            <br />
            <SubmitButton text="Next" btnType="primary" onClick={() => setCurrentStep(currentStep + 3)} />
        </>,
        <>
            <Field fieldLabel={`${entityType} Name`} key={14}>
                <InputFieldText fieldName="companyName" saveValue={preSaveValueFunct} />
            </Field>
            <Field fieldLabel={`${entityType} Trading Name`} key={15}>
                <InputFieldText fieldName="tradingName" saveValue={preSaveValueFunct} />
            </Field>
            <Field fieldLabel={`Number of Partners`} key={16}>
                <InputFieldText fieldName="numOfPartners" saveValue={preSaveValueFunct} fieldType={"number"} />
            </Field>
            <br />
            <SubmitButton text="Next" btnType="primary" onClick={() => setCurrentStep(currentStep + 2)} />
        </>,
        <>
            <Field fieldLabel={`${entityType} Trading Name`} key={17}>
                <InputFieldText fieldName="tradingName" saveValue={preSaveValueFunct} />
            </Field>
            <br />
            <SubmitButton text="Next" btnType="primary" onClick={() => setCurrentStep(currentStep + 1)} />
        </>,
        <>
            <Field fieldLabel={`Start typing ${entityType} address to search`} key={18}>
                <InputFieldAddressLookup fieldName={`company-address-lookup`} saveValue={saveSoleTraderAddress} />
            </Field>
            <Field fieldLabel={`${entityType} Address Line One`} key={19}>
                <InputFieldText fieldName="companyAddressLineOne" saveValue={preSaveValueFunct} populateValue={companyAddressOne} />
            </Field>
            <Field fieldLabel={`${entityType} Address Line Two`} key={20}>
                <InputFieldText fieldName="companyAddressLineTwo" saveValue={preSaveValueFunct} populateValue={companyAddressTwo} />
            </Field>
            <Field fieldLabel={`${entityType} Address Town`} key={21}>
                <InputFieldText fieldName="companyAddressTown" saveValue={preSaveValueFunct} populateValue={companyAddressLocality} />
            </Field>
            <Field fieldLabel={`${entityType} Address County`} key={22}>
                <InputFieldText fieldName="companyAddressCounty" saveValue={preSaveValueFunct} populateValue={companyAddressRegion} />
            </Field>
            <Field fieldLabel={`${entityType} Address PostCode`} key={23}>
                <InputFieldText fieldName="companyAddressPostcode" saveValue={preSaveValueFunct} populateValue={companyAddressPostcode} />
                <br />
                <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign("person")} />
            </Field>
        </>,
    ];

    const animateBeforeAssign = (assignment: string) => {
        setCurrentStep(-1);
        setTimeout(() => {
            assignGroup(assignment);
        }, 400);
    };

    const getCurrentStep = () => {
        return isActive == "business" ? (
            <FieldGroup title="">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    {businessSteps[postCurrentStep]}
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
