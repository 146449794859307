import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { apiErrors } from "../helpers/apiErrors";
import GreenBarDivider from "../components/pageComponents/greenBarDivider";
import HeroFiles from "../components/pageComponents/heroFiles";
import ClientNumbersAlt from "../components/pageComponents/clientNumbersAlt";

function UploadFiles() {
    const [resumeClaimData, setResumeClaimData] = useState<any>();
    const { token } = useParams();
    const [paramsObj, setParamsObj] = useState<any>({});
    const queryParameters = new URLSearchParams(window.location.href);

    const [claimResumed, setClaimResumed] = useState(true);
    const [requestingClaim, setRequestingClaim] = useState(true);

    useEffect(() => {
        Loading.standard("Loading...");
        const murl = `${process.env.REACT_APP_RESUME_DATA_ENDPOINT}`;
        console.log("my url: ", murl);

        axios
            .post(murl, { token })
            .then((response) => {
                const resumeData = response.data;
                console.log("resumeData:", resumeData);
                if (resumeData.success) {
                    setResumeClaimData(resumeData.claimObject);
                    setClaimResumed(true);
                } else {
                    throw new Error("failed....");
                }
                Loading.remove();
            })
            .catch((e) => {
                console.log("error:", e);
                Loading.remove();
                if (e.response) {
                    const serverError = e.response.data;
                    apiErrors(serverError);
                } else {
                    apiErrors("unknown");
                }
            });
    }, []);

    return (
        <div className={`${styles.energyClaimFormContainerUpload}`}>
            <HeroFiles claimData={resumeClaimData} />
            <ClientNumbersAlt />
        </div>
    );
}

export default UploadFiles;
