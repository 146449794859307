
import { Report } from "notiflix/build/notiflix-report-aio";
import { Loading } from "notiflix";

/**
 * "Couldn't retrieve company information"
 * 'Lead already signed' 
 */
export const apiErrors = (serverError: any) => {
    console.log(serverError);

    if (serverError === "Token not found" || serverError === "no token") {
        Report.failure(
            "Unable to locate account.",
            "We were unable to locate your case, contact support@myenergyrefunds.co.uk for more information.",
            "OK",
            () => window.location.href = "https://google.co.uk"

        );
    } else if (serverError === 'Pack already signed' || serverError == 'Lead already signed') {
        Report.success(
            "Documents Signed",
            "Your documents have already been completed.",
            "OK",
            () => window.location.href = serverError == 'Lead already signed' ? "https://claim.myenergyrefunds.co.uk/" : "https://google.co.uk"

        );

    } else if (serverError === "Couldn't retrieve company information") {
        Report.success(
            "Company Not Found",
            "We couldn't locate your company via companies house, please check your company number try again.",
            "OK",
            () => window.location.href = "https://claim.myenergyrefunds.co.uk/"
        );
    } else if (serverError.error === "Law firm not found.") {
        Report.failure(
            "Unknown Error",
            "We couldn't locate your claim details, please copy and past the link in the browser URL bar and try again.",
            "OK",
            () => window.location.href = "https://form.myenergyrefunds.com/claim/start/"
        );
    } else {
        console.log('ERROR - fail...');

        Report.failure(
            "Internal Error",
            "We've encountered an internal error, please try again later.",
            "OK",
            () => {
                // window.location.href = "https://google.co.uk";
            }
        );
    }

    return true
}