import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { CtaButton, Wrapper } from "../../../components/reusable";
import missoldEnergy from "../../../assets/missold-energy.png";

function MissoldEnergy() {
    return (
        <div className={`${styles.twoCols} ${responsiveStyles.twoCols}`}>
            <Wrapper>
                <div className={`${styles.twoColContainer} ${responsiveStyles.twoColContainer}`}>
                    <div className={styles.leftCol}>
                        <img src={missoldEnergy} alt={"missold"} />
                    </div>
                    <div className={styles.leftCol}>
                        <h2>Here's how mis-sold energy affects you</h2>
                        <div>
                            <p>
                                Millions of UK businesses rely on an energy broker to help them find the best deal on their business energy needs,
                                with these brokers earning commission on packages they source you.
                            </p>
                            <p>
                                If your business has used an energy broker, then they may have sold you an energy deal with added commission that goes
                                directly to them, without telling you the full details behind your contract.
                            </p>
                            <p>
                                Legally, energy brokers must disclose any commissions they earn through a deal, but an increasing amount of brokers
                                have - often deliberately - failed to disclose just how much money they're earning on commission.
                            </p>
                            <p>
                                Thousands of companies across the UK, ranging from microbusinesses to large-scale corporations and trusts, rely on the
                                work of honest brokers to get a business energy contract that's right for them.
                            </p>
                            <p>
                                By being mis-sold their deal, they face paying substantial amounts of money extra that can heavily impact the way they
                                operate: a particular concern in the ongoing energy and cost of living crisis.
                            </p>
                            <p>
                                Cases of mis-selling are rising, and your business shouldn't miss out and potentially keep losing money. Our free
                                online service is here to help you find out if you have been mis-sold your business energy deal.
                            </p>

                            <CtaButton text="CHECK MY ENERGY DEAL" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default MissoldEnergy;
