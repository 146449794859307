import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../reusable";
import { IsMobile } from "../layout";
import docsImg from "../../assets/fusionSign/docs.png";
import sigImg from "../../assets/fusionSign/signed.jpg";
import loader from "../../assets/fusionSign/loading.gif";
import Previewer from "../reusable/previewer";
import { SignaturePad } from "../reusable/sigPad";
import { Report } from "notiflix/build/notiflix-report-aio";

// import sigImg from "../../assets/fusionSign/sig.jpg";
import { useParams } from "react-router-dom";

function CompleteApplicationSign(props: { loaded: boolean; data: any; urlData: any }) {
    const { loaded, data, urlData } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useParams();
    useEffect(() => {
        console.log("loaded ? ", loaded);
        setIsLoaded(loaded);
    }, [loaded]);

    const completeSign = () => {
        Report.success(
            "Success",
            "Your document has been signed. We will be in touch in due course.",
            "Okay",
            () => (window.location.href = "https://claim.myenergyrefunds.co.uk/")
        );
    };
    return (
        <div className={`${styles.applicationSign} ${responsiveStyles.applicationSign}  ${!isLoaded ? styles.loading : ""} `}>
            {!isLoaded ? (
                <div>
                    <h1>Hi {data?.firstname} </h1>
                    <p>Please wait while we collate your claim information and generate your Letter of Authority document.</p>
                    <p>This will only take a few seconds</p>
                    <img src={loader} alt={"loading spinner"} />
                </div>
            ) : (
                <div className={`${styles.applicationSignInner} ${responsiveStyles.applicationSignInner}`}>
                    <h1>Sign your Letter of Authority</h1>
                    <p>
                        Please review and sign your document. Once we have received your signature we will proceed to send an information request to
                        your energy provider. We will use their response as a basis for our valuation of your claim.
                    </p>
                    <Previewer url={urlData.foaUrl} />
                    <Previewer url={urlData.infoReqUrl} />
                    <SignaturePad extraPayload={{ type: "contactFusionSign" }} additionalAction={completeSign} />
                </div>
            )}
        </div>
    );
}

export default CompleteApplicationSign;
