import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface fieldGroupInterface {
    title: string;
    children: React.ReactNode;
}

export const FieldGroup = (props: fieldGroupInterface) => {
    const { children, title } = props;
    return (
        <div className={styles.fieldGroup}>
            {/* <p className={styles.fieldGroupTitle}> {title}</p> */}
            {children}
        </div>
    );
};
