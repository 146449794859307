import styles from "./styles.module.scss";
import pageStyles from "../../../globals/pages.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import { Wrapper } from "../../../../reusable";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../../../../assets/logo.png";
import profile from "../../../../../assets/profile.png";
import { IsMobile } from "../../../isMobile/isMobile";
import axios from "axios";
import { useParams } from "react-router-dom";

export function OriginalHeader() {
    const [clientName, setClientName] = useState<String>("");
    const { token } = useParams();
    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_USERTOKEN_URL}`, { token })
            .then((response) => {
                const userData = response.data;
                console.log("data:", userData);
                setClientName(`${userData.name}`);
            })
            .catch((e) => {
                console.log("error:", e);
            });
    }, []);
    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <Wrapper>
                <div className={`${styles.innerHeader} ${responsiveStyles.innerHeader} `}>
                    <div className={`${styles.portalUserInfo} ${responsiveStyles.portalInfo} `}>
                        {/* <div className={`${styles.profileImgContainer}`}>
                            <img src={profile} alt="portal profile" />
                        </div> */}
                        {IsMobile() ? (
                            <div className={responsiveStyles.initialsCircle}>
                                <p>
                                    {clientName
                                        .split(" ")
                                        .map((e) => {
                                            return e.charAt(0);
                                        })
                                        .join("")}
                                </p>
                            </div>
                        ) : (
                            <>
                                <div className={`${styles.profileImgContainer}`}>
                                    <img src={profile} alt="portal profile" />
                                </div>
                                <p>{clientName}</p>
                            </>
                        )}
                    </div>
                    <div className={`${styles.logo} ${responsiveStyles.logo} `}>
                        {/* <a href="https://www.sentinellegal.co.uk" target="__blank">
                            <img src={logo} alt="Sentinel Legal Logo" />
                        </a> */}
                    </div>
                </div>
            </Wrapper>
        </header>
    );
}
