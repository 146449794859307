import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { CtaButton, Wrapper } from "../../reusable";
import secrets from "../../../assets/secrets-img.png";

function SecretCommission() {
    return (
        <div className={`${styles.twoCols} ${responsiveStyles.twoCols}`}>
            <Wrapper>
                <h2>Inside the secret cost of commissions</h2>
                <div className={`${styles.twoColContainer} ${responsiveStyles.twoColContainer}`}>
                    <div className={styles.leftCol}>
                        <div className={styles.pointsContainer}>
                            <div className={styles.points}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <ul>
                                <li>
                                    <span>Energy Cost</span>
                                    Actual cost of your energy 15p/kWh
                                </li>
                                <li>
                                    <span>Commission</span>
                                    Commission paid to broker 3p/kWh
                                </li>
                                <li>
                                    <span>Your Bill</span>
                                    Your bill will show 18p/kWh (which is paid to your supplier)
                                </li>
                                <li>
                                    <span>You Pay More</span>
                                    Your supplier pays the broker the 3p/kWh from your contract
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.rightCol}>
                        <div className={styles.yellowContainer}>
                            <img src={secrets} alt={"secrets"} />
                            <h3>Uncovering and recovering your costs</h3>
                            <p>
                                The latest estimates show that UK businesses could already be looking at paying four times the amount for business
                                energy as they paid in 2020.
                            </p>
                            <p>Our expert team is here to help you avoid paying extra commissions on top of these surging prices.</p>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <CtaButton text="CHECK MY ENERGY DEAL" />
        </div>
    );
}

export default SecretCommission;
