import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface fieldInterface {
    children: React.ReactNode;
    step: number;
    assignPostStep: (postStep: number) => void;
}

export const FormCard = (props: fieldInterface) => {
    const { children, step, assignPostStep } = props;
    const [cardVisibility, setCardVisibility] = useState("");

    useEffect(() => {
        if (step == -1) {
            setCardVisibility("hideFormCard");
        } else if (step >= 1) {
            setCardVisibility("hideFormCard");
            setTimeout(() => {
                setCardVisibility("");
                setTimeout(() => {
                    assignPostStep(step);
                    setCardVisibility("showFormCard");
                }, 400);
            }, 300);
        } else {
            setCardVisibility("showFormCard");
        }
    }, [step]);

    return <div className={`${styles.formCard} ${styles[cardVisibility]}`}>{children}</div>;
};
