import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";

import { UploadBills, CompleteForm } from "../formParts";
import { SaveValueInterface } from "../../../interfaces";
import { uploadFileToServer } from "../../../helpers";
import { filesArrayInterface } from "../../../interfaces";

function FileUploadForm(props: { claimRef: any }) {
    const { claimRef } = props;
    const [claimObject, setClaimObject] = useState<any>({});
    const [filesArray, setFilesArray] = useState<Array<filesArrayInterface>>([]);
    const [groupsComplete, setGroupsComplete] = useState(0);
    const [ref, setRef] = useState("");

    const [activeGroup, setActiveGroup] = useState("upload");

    useEffect(() => {
        console.log("claim referebce: ", claimRef);
        if (claimRef != "") setRef(claimRef);
    }, [claimRef]);

    useEffect(() => {
        console.log("FILES:....", filesArray);
    }, [filesArray]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const saveFieldValue = (dataToSave: SaveValueInterface) => {
        console.log(dataToSave);
        return { success: true, error: "" };
    };

    const activeGroupAssignment = async (group: string) => {
        setActiveGroup(group);
        if (group == "complete") {
            console.log(claimObject);
            const uploaded = await uploadFileToServer(ref, filesArray);
            if (!uploaded.success) {
                console.log("DO NOT CONTINUE....");
                return;
            }
        }
        console.log("SHOW NEXT STEPS...");
        const newGroupsComplete = groupsComplete + 1;
        setGroupsComplete(newGroupsComplete);
    };

    const saveFileToForm = (fileToSave: any) => {
        console.log(fileToSave);
        let filteredFiles = filesArray.filter((searchedFile) => searchedFile.fileInputName !== fileToSave.fileInputName);
        filteredFiles.push(fileToSave);
        setFilesArray(filteredFiles);
    };

    return (
        <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
            <form className={`${styles.form}`} onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formFields}>
                    <UploadBills
                        saveValueFunct={saveFieldValue}
                        isActive={activeGroup}
                        assignGroup={activeGroupAssignment}
                        claimData={{ ...claimObject }}
                        attachFile={saveFileToForm}
                    />
                    <CompleteForm isActive={activeGroup} saveValueFunct={saveFieldValue} assignGroup={activeGroupAssignment} />
                </div>
            </form>
        </div>
    );
}

export default FileUploadForm;
