import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { OriginalHeader, ContactFusionHeader } from "./headerTypes";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";
import { CourmacsHeader } from "./headerTypes/courmacsHeader";

function Header() {
    const [headerType, setHeaderType] = useState<String>("");
    const { token } = useParams();

    useEffect(() => {
        assignHeaderType();
        // build_tracking('https://www.googletagmanager.com/gtag/js?id=G-6BHJ8CH3Y4"');
        ReactGA.initialize("G-6BHJ8CH3Y4");

        const siteId = 3632308;
        const hotjarVersion = 6;
        Hotjar.init(siteId, hotjarVersion);
    }, []);

    function assignHeaderType() {
        const pcs = window.location.href.split("/");
        setHeaderType(pcs[pcs.length - 2]);
    }

    function selectHeader() {
        console.log(headerType);

        console.log("header type: ", headerType);

        switch (headerType) {
            case "fusion-sign":
                return <ContactFusionHeader />;
                break;
            case "introduced-sign":
                return <OriginalHeader />;
                break;
            case "courmacs":
                return <CourmacsHeader />;
                break;
            case "upload":
                return <ContactFusionHeader />;
                break;
            case "claim":
                return <ContactFusionHeader />;
                break;
            default:
                return <ContactFusionHeader />;
                break;
        }
    }
    return selectHeader();
}

export default Header;
