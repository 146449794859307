import styles from "./styles.module.scss";
import { Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import upwardGraph from "../../../assets/upwards-graph.png";
function ClientNumbers() {
    return (
        <div className={`${styles.clientNumbers} ${responsiveStyles.clientNumbers} `}>
            <Wrapper>
                <div className={`${styles.clientNumbersInner} ${responsiveStyles.clientNumbersInner}`}>
                    <p>
                        <span>Numbers</span>
                        Client Results
                    </p>

                    <p>
                        13,583 +<span>Successful mis-selling claims</span>
                    </p>
                    <p>
                        £ 2,475,498 +<span>Returned to our clients</span>
                    </p>
                </div>
            </Wrapper>
        </div>
    );
}

export default ClientNumbers;
