import styles from "./styles.module.scss";
import { CtaButton, Wrapper } from "../../../components/reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

function HowCanWeHelp() {
    return (
        <div className={`${styles.centralContainer} ${responsiveStyles.centralContainer}  `}>
            <Wrapper>
                <div className={`${styles.titleContainer} ${responsiveStyles.titleContainer}  `}>
                    <h2>How we can help you find out if you are due energy compensation</h2>
                    <p>
                        We have a simple and straightforward 3-step process to help you find out if you are due thousands of pounds back in
                        compensation for being mis-sold your energy contract. There are no hidden costs – our Free Eligibility Check will not cost you
                        a penny!
                    </p>
                </div>
                <div className={`${styles.threePoints} ${responsiveStyles.threePoints}  `}>
                    <div>
                        <span>1</span>
                        <p>Fill in our Claim Form</p>
                        <p>
                            Simply fill in a few details on our short claim form. We just need to know your business details, contact information and
                            the name of your energy provider. It takes less than a minute!
                        </p>
                    </div>
                    <div>
                        <span>2</span>
                        <p>Sign Your Letter of Authority</p>
                        <p>
                            If the details on your claim form show that you may have been mis-sold your energy contract, all you need to do is sign a
                            Letter of Authority and we will continue to progress the claim on your behalf.
                        </p>
                    </div>
                    <div>
                        <span>3</span>
                        <p>Get Your Compensation</p>
                        <p>
                            Once your claim has been forwarded to our expert legal team, it will take some time to process the details and provide a
                            resolution. We will keep you informed of the progress of your case every step of the way.
                        </p>
                    </div>
                </div>
                <CtaButton text="CHECK MY ENERGY DEAL" />
            </Wrapper>
        </div>
    );
}

export default HowCanWeHelp;
