
import { Buffer } from "buffer";
import axios from "axios";
export const companySearch = async (searchValue: string) => {
    const encodedKey = Buffer.from(`${process.env.REACT_APP_COMPANIES_HOUSE_API_KEY}:`).toString("base64");

    let axiosData = {
        method: "GET",
        headers: {
            Authorization: `Basic ${encodedKey}`,
        },
        url: `https://api.company-information.service.gov.uk/search/companies?q=${searchValue}`,
        timeout: 180000, //optional
    };

    const companiesHouseSearchNetworkResult = await axios(axiosData);
    // console.log("COMPANIES HOUSE DATA: ", companiesHouseSearchNetworkResult);
    return companiesHouseSearchNetworkResult.data
}