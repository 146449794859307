export const requiredFields = [
    'title',
    'firstname',
    'lastname',
    'telephone',
    'email',
    'previousSuppliers'
]
export const requiredPersonFields = [
    'title',
    'firstname',
    'lastname',
    'telephone',
    'email',
]

export const requiredDefineSitesFields = [
    'multisite',
]

export const requiredEnergyContractFields = [
    'energy_supplier',
    'contract_type',
    'energybrokerUsed',
    'contract_term',
    'hiddenCommissionKnown',
    'monthly_bill',
]
