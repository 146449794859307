import React, { useEffect, useState } from "react";
// import styles from "./styles.module.scss";
import { Report } from "notiflix/build/notiflix-report-aio";

import { titleData } from "../../../../data/titles";
import { contactPreferencesData } from "../../../../data/contactPreferences";
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard } from "../../../reusable";

import { FormPartInterface, SaveValueInterface } from "../../../../interfaces";
import { positionData } from "../../../../data/positions";
import { Checkbox } from "../../../reusable/checkbox";

export const AuthorisedPerson = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave);
        return saved;
    };

    const animateBeforeAssign = (assignment: string) => {
        if (typeof validateSection !== "undefined") {
            if (!validateSection("authorisedPerson").success) return;
            saveValueFunct({ field: "preSubmit", value: "success" });
            setCurrentStep(-1);
            setTimeout(() => {
                assignGroup(assignment);
            }, 400);
        }
    };

    const getCurrentStep = () => {
        return isActive == "person" ? (
            <FieldGroup title="Contact Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <Field extraStyle={{ padding: "0" }} labelPosition="left" fieldLabel={`What's your role within the business?`} key={11}>
                        <InputFieldSelect fieldName="position" saveValue={preSaveValueFunct} options={positionData}></InputFieldSelect>
                    </Field>
                    <FieldContainer>
                        {/* <div style={{ maxWidth: "none", textAlign: "left" }}> */}
                        <Field extraStyle={{ padding: "0" }} fieldLabel="Title" labelPosition="left">
                            <InputFieldSelect fieldName="title" saveValue={preSaveValueFunct} options={titleData}></InputFieldSelect>
                        </Field>
                        {/* </div> */}
                        <Field fieldLabel="Firstname" labelPosition="left">
                            <InputFieldText fieldName="firstname" saveValue={preSaveValueFunct} />
                        </Field>
                        <Field extraStyle={{ padding: "0" }} fieldLabel="Lastname" labelPosition="left">
                            <InputFieldText fieldName="lastname" saveValue={preSaveValueFunct} />
                        </Field>
                    </FieldContainer>
                    <FieldContainer>
                        <Field extraStyle={{ padding: "0" }} fieldLabel="Telephone" labelPosition="left">
                            <InputFieldText fieldName="telephone" saveValue={preSaveValueFunct} fieldType={"tel"} />
                        </Field>
                        <Field extraStyle={{ padding: "0" }} fieldLabel="Email" labelPosition="left">
                            <InputFieldText fieldName="email" saveValue={preSaveValueFunct} fieldType={"email"} />
                        </Field>
                    </FieldContainer>
                    <Field extraStyle={{ padding: "0", margin: "0px 0 30px" }} fieldLabel={``} key={12}>
                        <Checkbox
                            fieldName="termsAccepted"
                            label="This website is provided by Business Energy Refunds Limited. Access to and use
                                of this website is subject to the following terms and conditions. By
                                accessing this website, you confirm your acceptance of these terms
                                and conditions of use. Business Energy Refunds Limited reserves the right to
                                change these terms and conditions at any time and will not be obliged
                                to notify you of any such changes. You are therefore advised to check
                                these terms and conditions regularly and that your continued use of the
                                website following any such change indicates your acceptance of the
                                new terms."
                            saveValue={preSaveValueFunct}
                        />
                    </Field>
                    {/* <Field extraStyle={{ padding: "0" }} fieldLabel={``} key={13}>
                        <Checkbox
                            fieldName="authorisationToAct"
                            label="I confirm I have authorisation to act on behalf of my business in agreeing to Harcus Parker's retainer, and give my full consent for Proaxis Legal Ltd to pass all information relating to myself and my company onto Harcus Parker for the purposes of pursuing a claim of the type described in the retainer."
                            saveValue={preSaveValueFunct}
                        />
                    </Field> */}
                    <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign("complete")} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
