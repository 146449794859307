import s from "./styles.module.scss";

export interface fieldContainerProps {
    children: React.ReactNode;
}

export const FieldContainer = (props: fieldContainerProps) => {
    const { children } = props;
    return <div className={s.sidebyside}>{children}</div>;
};
