import { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../../../assets/logo.png";
import { Wrapper } from "../../reusable";
import { OriginalFooter, ContactFusionFooter } from "./footerTypes";
import { LawfirmFooter } from "./footerTypes/lawfirmFooter";

function Footer() {
    const [footerType, setFooterType] = useState<String>("");

    useEffect(() => {
        assignFooterType();
    }, []);

    function assignFooterType() {
        const pcs = window.location.href.split("/");
        setFooterType(pcs[pcs.length - 2]);
    }
    function selectFooter() {
        switch (footerType) {
            case "fusion-sign":
                return <ContactFusionFooter />;
                break;
            case "claim":
                return <ContactFusionFooter />;
                break;
            case "introduced-sign":
                return <OriginalFooter />;
                break;

            case "courmacs":
                return <LawfirmFooter />;
                break;
            default:
                return <OriginalFooter />;
                break;
        }
    }
    return selectFooter();
}

export default Footer;
