import styles from "./styles.module.scss";
import { Wrapper } from "../../../components/reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import upwardGraph from "../../../assets/chart-up.png";
function GreenBarDivider() {
    return (
        <div className={`${styles.greenBar} ${responsiveStyles.greenBar}    `}>
            <Wrapper>
                <div className={`${styles.greenBarInner}  ${responsiveStyles.greenBarInner}  `}>
                    <p>£389m has already been paid back in redress payments</p>
                    <img src={upwardGraph} />
                    <p>….and this number is rising.</p>
                </div>
            </Wrapper>
        </div>
    );
}

export default GreenBarDivider;
