import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";

import axios from "axios";
import { useParams } from "react-router-dom";
import Notiflix, { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../../reusable";

import { AuthorisedPerson, Business, QualifyingQuestions, EnergyContract, DefineSites, UploadBills, CompleteForm } from "../formParts";

import { SaveValueInterface } from "../../../interfaces";
import { fieldValidation, sectionValidation, uploadDataToServer, uploadFileToServer } from "../../../helpers";
import { filesArrayInterface } from "../../../interfaces";

function ClaimForm() {
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const [claimObject, setClaimObject] = useState<any>({});
    const [filesArray, setFilesArray] = useState<Array<filesArrayInterface>>([]);
    const [additionalFiles, setAdditionalFiles] = useState("");
    const [groupsComplete, setGroupsComplete] = useState(0);
    const [widthState, setWidthState] = useState("0%");
    const [activeGroup, setActiveGroup] = useState("energyContract");
    // const [activeGroup, setActiveGroup] = useState("business");
    // const [activeGroup, setActiveGroup] = useState("person");
    // const [activeGroup, setActiveGroup] = useState("upload");
    const [assignedSites, setAssignedSites] = useState(0);
    console.log("test...");

    useEffect(() => {
        if (!submissionComplete) {
            // console.log("return to home page....");
        }
    }, [submissionComplete]);
    useEffect(() => {
        console.log("FILES:....", filesArray);
    }, [filesArray]);

    const submitClaim = async () => {
        try {
            console.log(claimObject);
            Loading.standard("Validating Claim...");
            // submitValidation(claimObject, filesArray);
            // const attemptUpload = await uploadFileToServer(filesArray, claimObject);
            Loading.remove();
        } catch (error: any) {
            Loading.remove();
            Report.failure("Error", error.message, "ok");
        }
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const saveFieldValue = (dataToSave: SaveValueInterface) => {
        console.log(dataToSave);
        let tempClaimObject = claimObject;

        if (tempClaimObject) {
            tempClaimObject[dataToSave.field] = dataToSave.value;
        }

        setClaimObject(tempClaimObject);
        // console.log("CLAIM OBJECT: ", claimObject);

        const validated = fieldValidation(dataToSave);

        if (dataToSave.field == "energyType") {
            console.log("contract type: ", dataToSave.value);
            setAdditionalFiles(dataToSave.value);
        }
        if (validated.success) {
            return validated;
        } else {
            return validated;
        }
    };

    const activeGroupAssignment = async (group: string) => {
        setActiveGroup(group);
        if (group == "energyContract") return;

        if (group == "complete") {
            console.log(claimObject);
            const uploaded = await uploadDataToServer(claimObject);
            if (uploaded.success) {
            } else {
                console.log("DO NOT CONTINUE....");
                return;
            }
        }

        // if (group == "complete") {
        //     // console.log(claimObject);
        //     // const uploaded = await uploadFileToServer(claimObject.id, filesArray);
        //     // if (uploaded.success) {
        //     //     let tempClaimObject = claimObject;
        //     //     tempClaimObject.id = uploaded.id;
        //     //     setClaimObject(tempClaimObject);
        //     // } else {
        //     //     console.log("DO NOT CONTINUE....");
        //     //     return;
        //     // }
        // }

        console.log("SHOW NEXT STEPS...");
        const newGroupsComplete = groupsComplete + 1;
        setGroupsComplete(newGroupsComplete);
        const newWidth = group !== "complete" ? `${newGroupsComplete * 33}%` : `100%`;
        setWidthState(newWidth);
    };

    const saveFileToForm = (fileToSave: any) => {
        // console.log(fileToSave);
        let filteredFiles = filesArray.filter((searchedFile) => searchedFile.fileInputName !== fileToSave.fileInputName);
        filteredFiles.push(fileToSave);
        setFilesArray(filteredFiles);
    };

    const validateGroup = (section: string) => {
        const isValid = sectionValidation(claimObject, section);
        // console.log("check is valid: ", isValid);

        return isValid;
    };

    return (
        // <Wrapper>
        <div className={`${styles.formContainer} ${responsiveStyles.formContainer}`}>
            {/* 
                <div className={styles.formTitle}>
                    <p>Takes 5 Minutes </p>
                </div> 
            */}
            <div className={`${styles.progressContainer} ${responsiveStyles.progressContainer}`}>
                <div className={styles.progressBar}>
                    <div style={{ width: widthState }} className={styles.progressBarContent}></div>
                </div>
                <div className={`${styles.progressItem} ${styles.complete}`}>
                    <p>
                        <span>1</span>
                        <span>Energy Contract</span>
                    </p>
                </div>
                <div className={`${styles.progressItem} ${groupsComplete > 1 ? styles.complete : ""}`}>
                    <p>
                        <span>2</span>
                        <span>Business Information</span>
                    </p>
                </div>
                <div className={`${styles.progressItem} ${groupsComplete > 2 ? styles.complete : ""}`}>
                    <p>
                        <span>3</span>
                        <span>Contact Details</span>
                    </p>
                </div>
                <div className={`${styles.progressItem} ${groupsComplete > 3 ? styles.complete : ""}`}>
                    <p>
                        <span>4</span>
                        <span>Complete</span>
                    </p>
                </div>
                {/* 
                <div className={`${styles.progressItem} ${groupsComplete > 4 ? styles.complete : ""}`}>
                    <p>
                        <span>5</span>
                        <span>Complete</span>
                    </p>
                </div> */}
            </div>
            <form className={`${styles.form}`} onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formFields}>
                    <EnergyContract
                        saveValueFunct={saveFieldValue}
                        isActive={activeGroup}
                        assignGroup={activeGroupAssignment}
                        sitesToCreate={assignedSites}
                        claimData={{ ...claimObject }}
                        validateSection={validateGroup}
                    />
                    <Business saveValueFunct={saveFieldValue} isActive={activeGroup} assignGroup={activeGroupAssignment} />
                    <AuthorisedPerson
                        saveValueFunct={saveFieldValue}
                        isActive={activeGroup}
                        assignGroup={activeGroupAssignment}
                        validateSection={validateGroup}
                    />
                    {/* <UploadBills
                        saveValueFunct={saveFieldValue}
                        isActive={activeGroup}
                        assignGroup={activeGroupAssignment}
                        claimData={{ ...claimObject }}
                        attachFile={saveFileToForm}
                    /> */}
                    <CompleteForm isActive={activeGroup} saveValueFunct={saveFieldValue} assignGroup={activeGroupAssignment} />
                    {/* <QualifyingQuestions
                        saveValueFunct={saveFieldValue}
                        isActive={activeGroup}
                        assignGroup={activeGroupAssignment}
                        validateSection={validateGroup}
                    /> */}
                    {/* <DefineSites
                        saveValueFunct={saveFieldValue}
                        isActive={activeGroup}
                        assignGroup={activeGroupAssignment}
                        buildSites={allocateSites}
                        validateSection={validateGroup}
                    /> */}
                </div>
            </form>
        </div>
        // </Wrapper>
    );
}

export default ClaimForm;
