import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface fieldInterface {
    fieldLabel: string;
    children: React.ReactNode;
    labelPosition?: string;
    extraStyle?: any;
}

export const Field = (props: fieldInterface) => {
    const { children, fieldLabel, labelPosition, extraStyle } = props;
    return (
        <div style={extraStyle} className={styles.field}>
            <label className={`${labelPosition ? styles[labelPosition] : ""}`}>{fieldLabel}</label>
            {children}
        </div>
    );
};
