import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ScrollToTop } from "./components/layout/scrollTop/scrollToTop";

import Main from "./containers/main";
import IntroducedSign from "./containers/introducedSign";
import ContactFusionSign from "./containers/contactFusionSign";
import MakeClaim from "./containers/makeClaim";
import UploadFiles from "./containers/uploadFiles";
import BecSign from "./containers/becSign";

export default function AppRouter() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route path="introduced-sign/" element={<IntroducedSign />}>
                        <Route path=":token" element={<IntroducedSign />} />
                    </Route>
                    <Route path="fusion-sign/" element={<ContactFusionSign />}>
                        {/* <Route path=":token" element={<ContactFusionSignOld />} /> */}
                        <Route path=":token" element={<ContactFusionSign />} />
                    </Route>
                    <Route path="sign/" element={<BecSign />}>
                        <Route path="courmacs/:token" element={<BecSign />} />
                        <Route path="harcus-parker/:token" element={<BecSign />} />
                        <Route path="probitas/:token" element={<BecSign />} />
                    </Route>
                    <Route path="claim/start" element={<MakeClaim />} />
                    <Route path="claim/upload/:token" element={<UploadFiles />} />
                </Route>
            </Routes>
        </Router>
    );
}
