import { useEffect, useState } from "react";
import s from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Data8Service } from "../../../services";

interface inputFieldTextProps {
    fieldName: string;
    saveValue: (obj: { field: string; value: string }) => { success: boolean; error: string };
    submitValue?: any;
}

export const InputFieldAddressLookup = (props: inputFieldTextProps) => {
    const { fieldName, saveValue } = props;
    const [valueToSave, setValueToSave] = useState("");
    const [error, setError] = useState("");
    const [lookupResults, setLookupResults] = useState([]);

    useEffect(() => {}, []);

    const onChangeFunc = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        console.log("INPUT TEXT FIELD CHANGED...", newValue);
        setValueToSave(newValue);

        if (newValue.length > 3) {
            await data8Lookup(newValue);
        }
        // saveAll(newValue);
    };

    const saveAll = async (newValue: string) => {
        setValueToSave(newValue);
        const saveResult = saveValue({ field: fieldName, value: newValue });
        // console.log(saveResult);
        if (!saveResult.success) {
            setError(saveResult.error);
        }
    };

    const data8Lookup = async (searchTerm: string) => {
        const lookupObject = {
            country: "GB",
            search: searchTerm,
            telephoneNumber: "",
            session: "",
            options: {
                IncludeMR: false,
                IncludeNYB: false,
                PreferredLanguage: "en",
            },
        };

        const data8Result = await Data8Service("PredictiveAddress", "Search", lookupObject);
        console.log(data8Result);

        if (!data8Result.Results || !data8Result.Results.length) {
            setError("Couldn't find address.");
        } else {
            setError("");
        }
        setLookupResults(data8Result.Results);
    };

    const selectAddress = (address: string) => {
        console.log("adderss.....", address);
        saveValue({ field: fieldName, value: address });
        setLookupResults([]);
    };

    return (
        <>
            <div className={s.lookupContainer}>
                <input className={`${s.inputStyle}`} type={"text"} name={fieldName} value={valueToSave} onChange={onChangeFunc} />
                <span className={`${s.notice} {${error.length < 1 ? "error" : ""}`}>{error}</span>
                {lookupResults.length > 0 ? (
                    <>
                        <div className={s.lookupSelection}>
                            <p>Please select adddress:</p>
                            <ul>
                                {lookupResults.map((item: any, index: number) => {
                                    return <li onClick={() => selectAddress(item.label)}>{item.label}</li>;
                                })}
                            </ul>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
