import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../reusable";
import { IsMobile } from "../layout";

function PackSignBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                {IsMobile() ? (
                    <h1>
                        Review and Sign you <br />
                        Business Energy Refund Documents
                    </h1>
                ) : (
                    <h1> Review and Sign Your Business Energy Refund Documents</h1>
                )}
                <p>Please read and sign your Business Energy Refund Documents to progress your claim.</p>
            </Wrapper>
        </div>
    );
}

export default PackSignBanner;
