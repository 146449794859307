import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

import { SubmitButton, InputFile, FormCard } from "../../../reusable";

import { FormPartInterface } from "../../../../interfaces";

export const UploadBills = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, claimData, attachFile } = props;

    const [currentStep, setCurrentStep] = useState(1);
    const [postCurrentStep, setPostCurrentStep] = useState(0);

    useEffect(() => {
        console.log("CLAIM DATA: ", claimData);
    }, []);
    const uploadDocuments = () => {
        console.log("upload docs now....");
        setCurrentStep(-1);
        setTimeout(() => {
            assignGroup("complete");
        }, 400);
    };

    const getCurrentStep = () => {
        return isActive == "upload" ? (
            <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                <div className={styles.uploadSections}>
                    <h3>Please upload an energy bill to progress your claim.</h3>
                    <p style={{ maxWidth: "100%", margin: "20px 0" }}>
                        Optionally, upload the energy contract to optimise the potential outcome of your claim
                    </p>
                    <div className={styles.uploadSection}>
                        <div className={styles.site}>
                            <h4>{claimData.companyName}</h4>
                            <div className={styles.siteInnerContainer}>
                                <div className={styles.files}>
                                    <InputFile text={`Energy Bill`} niceName={``} fileInputName={`energyBill`} attachFileToForm={attachFile} />
                                    <InputFile
                                        text={`Energy Contract`}
                                        niceName={``}
                                        fileInputName={`energyContract`}
                                        attachFileToForm={attachFile}
                                    />
                                </div>
                                {/* <div className={styles.breakdown}>
                                    <div>
                                        <h5>Breakdown:</h5>
                                        <ul>
                                            <li>Energy Supplier: {claimData.energySupplier}</li>
                                            <li>Contract Term: {claimData.contractTerm} Years</li>
                                            <li>Contract Type: {claimData.contractType}</li>
                                        </ul>
                                        <h5>Company address:</h5>
                                        <ul>
                                            <li>{claimData.company_name}</li>
                                            <li>{claimData.company_address_one}</li>
                                            {claimData.company_address_two ? <li>{claimData.company_address_two}</li> : ""}
                                            <li>{claimData.company_locality}</li>
                                            <li>{claimData.company_postcode}</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <SubmitButton text={"Upload"} btnType="primary" onClick={() => uploadDocuments()} />
                </div>
            </FormCard>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
