import styles from "./styles.module.scss";
import { Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import FileUploadForm from "../../fileUploadForm/main";
import { useEffect, useState } from "react";
function HeroFiles(props: { claimData: any }) {
    const { claimData } = props;
    const [ref, setRef] = useState("");

    useEffect(() => {
        console.log("claim data: ", claimData);
        if (claimData != null && claimData?.reference != null) setRef(claimData.reference);
    }, [claimData]);
    return (
        <div className={`${styles.hero} ${responsiveStyles.hero}  `}>
            <Wrapper>
                <div className={`${styles.heroContent} ${responsiveStyles.heroContent}`}>
                    <span>Complete your application</span>
                    <h1>
                        Welcome back {claimData?.firstname}, <br />
                        Upload Your Energy Bill
                        <br /> & Energy Contract?
                    </h1>
                    <p>
                        To progress your claim we need a copy of your energy bill and contract, please upload it below.
                        <br />
                    </p>
                    <FileUploadForm claimRef={ref} />
                </div>
            </Wrapper>
        </div>
    );
}

export default HeroFiles;
