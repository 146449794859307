import React, { useState } from "react";
import s from "./styles.module.scss";
import { Link } from "react-router-dom";
import Select from "react-select";

interface selectObjectProps {
    value: string;
    label: string;
}

interface inputFieldSelectProps {
    readonly options: Array<selectObjectProps>;
    fieldName: string;
    saveValue: (obj: { field: string; value: string }) => void;
}

export const InputFieldSelect = (props: inputFieldSelectProps) => {
    const { options, saveValue, fieldName } = props;
    const [valueToSave, setValueToSave] = useState<selectObjectProps>();

    const onChange = (option: selectObjectProps | null) => {
        if (option != null) {
            console.log("INPUT SELECT FIELD CHANGED...", option.value);
            setValueToSave(option);
            saveValue({ field: fieldName, value: option.value });
        }
    };
    return <Select className={s.inputSelectStyle} options={options} onChange={onChange} value={valueToSave} />;
};
