import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../../reusable";
import { IsMobile } from "../../layout";
import { useClaimData } from "../../../claimProvider";

function HpSignBanner() {
    const claimData = useClaimData();
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                <h1>
                    Review and Sign HP!
                    <br />
                    <span>Your claim has been referred to our panel law firm.</span>
                </h1>
                <p>
                    To progress <b>{claimData.company_name}'s</b> claim for a business energy refund against <b>{claimData.energy_supplier}</b>,{" "}
                    <br />
                    please <b>review and sign</b> your client care pack.
                </p>
            </Wrapper>
        </div>
    );
}

export default HpSignBanner;
