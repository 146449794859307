import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../../../assets/logo.png";
import { Wrapper } from "../../../../reusable";

export function OriginalFooter() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    {/* <img src={logo} /> */}
                    <p className={styles.disclaimer}>
                        Your Business Energy Refunds is a website and trading name operated by Proaxis Legal Ltd, a Claims Management Company
                        authorised and regulated by the Financial Conduct Authority, reference number 833988. Proaxis Legal Ltd is a private limited
                        company, registered in England and Wales under Company number 4494454. VAT number 800564657, registered address is Portal
                        Business Centre, Bridgewater House, North Road, Ellesmere Port, Cheshire, CH65 1AF. Proaxis Legal Ltd does not charge
                        customers for their service and in return for passing customer details to a Solicitor Proaxis Legal Ltd receive a fee for
                        their marketing services. If successful in any claim for compensation and by way of deduction the solicitor will charge a
                        percentage of any compensation awarded that will have been discussed and agreed before starting your case. There are no hidden
                        or up-front costs and if any claim is unsuccessful no charge will be made.
                    </p>
                    <p>
                        <a target="__blank" href="https://www.sentinellegal.co.uk/privacy-policy">
                            Terms of Business
                        </a>
                        <a target="__blank" href="https://www.sentinellegal.co.uk/privacy-policy">
                            Privacy Notice
                        </a>
                    </p>
                </div>
            </Wrapper>
        </div>
    );
}
