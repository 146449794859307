import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { FieldGroup, Field, SubmitButton, SelectButton, OptionButton, ButtonContainer, FormCard, FieldContainer } from "../../../reusable";

import { InputFieldText, InputFieldDate, InputFieldSelect } from "../../../reusable";
import { FormPartInterface, SaveValueInterface } from "../../../../interfaces";
import { companySearch } from "../../../../services/companySearch";
import { supplierData } from "../../../../data/energySuppliers";
import { contractTypes } from "../../../../data/contractTypes";
import { InputFieldAddressLookup } from "../../../reusable/inputFieldAddressLookup";
import { Report } from "notiflix/build/notiflix-report-aio";
const validFields = ["contractType", "energySupplier", "hiddenCommissionKnown", "energybrokerUsed", "monthlyBill"];

export const EnergyContract = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [energyBroker, setEnergyBroker] = useState("");
    const [contractTerm, setContractTerm] = useState("");
    const [postCurrentStep, setPostCurrentStep] = useState(0);

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        let result = saveValueFunct(dataToSave);
        if ("broker" == dataToSave.field) setEnergyBroker(dataToSave.value);
        if (!result.success) return { success: false, error: "" };
        if (currentStep + 1 == questionsSteps.length) completeStep();
        // else if ("contractTerm" == dataToSave.field) setContractTerm(dataToSave.value);
        else if ("broker" == dataToSave.field && dataToSave.value == "don't know") setCurrentStep(currentStep + 1);
        else if (
            ["energy_supplied", "energy_supplier", "monthly_bill", "contract_term", "hiddenCommissionKnown", "energybrokerUsed"].some(
                (q) => q == dataToSave.field
            )
        )
            setCurrentStep(currentStep + 1);

        return { success: true, error: "" };
    };
    const completeStep = () => {
        assignGroup("business");
    };
    const checkEnergyBroker = () => {
        if (energyBroker.length > 0) setCurrentStep(currentStep + 1);
        else Report.failure("Error", "Please provide an energy broker.", "OK");
    };
    const checkContractTerm = () => {
        if (contractTerm.length > 0) setCurrentStep(currentStep + 1);
        else Report.failure("Error", "Please enter the term of your contract.", "OK");
    };

    const questionsSteps = [
        <Field fieldLabel={`Enter the name of your business energy supplier`} key={1}>
            <InputFieldSelect fieldName={`energy_supplier`} saveValue={preSaveValueFunct} options={supplierData}></InputFieldSelect>
        </Field>,
        <Field fieldLabel={`What type of energy was supplied?`} key={2}>
            {/* <InputFieldSelect fieldName={`contractType`} saveValue={preSaveValueFunct} options={contractTypes}></InputFieldSelect> */}
            {/* <ButtonContainer> */}
            <SubmitButton text="Electric" btnType="primary" onClick={() => preSaveValueFunct({ field: "energy_supplied", value: "electricity" })} />
            <br />
            <SubmitButton text="Gas" btnType="primary" onClick={() => preSaveValueFunct({ field: "energy_supplied", value: "gas" })} /> <br />
            <SubmitButton text="Dual" btnType="primary" onClick={() => preSaveValueFunct({ field: "energy_supplied", value: "dual" })} />
            {/* </ButtonContainer>{" "} */}
        </Field>,
        // <Field fieldLabel={`Did you use a comparison site or third party set up your energy contract?`} key={3}>
        //     {/* <ButtonContainer> */}
        //     <SubmitButton text="Yes" btnType="primary" onClick={() => preSaveValueFunct({ field: "energybrokerUsed", value: "yes" })} /> <br />
        //     <SubmitButton text="No" btnType="primary" onClick={() => preSaveValueFunct({ field: "energybrokerUsed", value: "no" })} /> <br />
        //     <SubmitButton text="Don't know" btnType="primary" onClick={() => preSaveValueFunct({ field: "energybrokerUsed", value: "yes" })} />
        //     {/* </ButtonContainer> */}
        // </Field>,
        <Field fieldLabel={`What is the name of your energy broker`} key={4}>
            <InputFieldText fieldName="broker" saveValue={preSaveValueFunct} populateValue={""} /> <br />
            <SelectButton
                text="Don't know the energy broker"
                btnType="primary"
                onClick={() => preSaveValueFunct({ field: "broker", value: "don't know" })}
            />
            <br />
            <SubmitButton text="Next" btnType="primary" onClick={() => checkEnergyBroker()} />
        </Field>,
        <Field fieldLabel={`Did you know there could have been hidden commission within your business energy contract?`} key={5}>
            <ButtonContainer>
                <SubmitButton text="Yes" btnType="primary" onClick={() => preSaveValueFunct({ field: "hiddenCommissionKnown", value: "yes" })} />
                <br />
                <SubmitButton text="No" btnType="primary" onClick={() => preSaveValueFunct({ field: "hiddenCommissionKnown", value: "no" })} />
            </ButtonContainer>
        </Field>,
        <Field fieldLabel="What is the contract term in years?" key={6}>
            <SelectButton text="1 Years" btnType="primary" onClick={() => preSaveValueFunct({ field: "contract_term", value: "1" })} />
            <br />
            <SelectButton text="2 Years" btnType="primary" onClick={() => preSaveValueFunct({ field: "contract_term", value: "2" })} />
            <br />
            <SelectButton text="3 Years" btnType="primary" onClick={() => preSaveValueFunct({ field: "contract_term", value: "3" })} />
            <br />
            <SelectButton text="4 Years" btnType="primary" onClick={() => preSaveValueFunct({ field: "contract_term", value: "4" })} />
            <br />
            <SelectButton text="5 Years" btnType="primary" onClick={() => preSaveValueFunct({ field: "contract_term", value: "5" })} />
            <br />
            <SelectButton text="6 Years" btnType="primary" onClick={() => preSaveValueFunct({ field: "contract_term", value: "6" })} />
            {/* <InputFieldSelect fieldName={`contractType`} saveValue={preSaveValueFunct} options={contractTypes}></InputFieldSelect> */}
            {/* <SubmitButton text="Next" btnType="primary" onClick={() => checkContractTerm()} /> */}
        </Field>,
        <Field fieldLabel="How much is your monthly bill" key={7}>
            {/* <ButtonContainer> */} <br />
            <SelectButton text="£0 - £500" btnType="primary" onClick={() => preSaveValueFunct({ field: "monthly_bill", value: "0-500" })} />
            <br />
            <SelectButton text="£501 - £750" btnType="primary" onClick={() => preSaveValueFunct({ field: "monthly_bill", value: "501-750" })} />
            <br />
            <SelectButton text="£751 - £1000" btnType="primary" onClick={() => preSaveValueFunct({ field: "monthly_bill", value: "751-1000" })} />
            <br />
            <SelectButton text="£1001 - £2000" btnType="primary" onClick={() => preSaveValueFunct({ field: "monthly_bill", value: "1001-2000" })} />
            <br />
            <SelectButton text="£2001 - £3000" btnType="primary" onClick={() => preSaveValueFunct({ field: "monthly_bill", value: "2001-3000" })} />
            <br />
            <SelectButton text="£3001 +" btnType="primary" onClick={() => preSaveValueFunct({ field: "monthly_bill", value: "3001+" })} />
            {/* </ButtonContainer> */}
        </Field>,
    ];

    const getCurrentStep = () => {
        return isActive == "energyContract" ? (
            <FieldGroup title="Do You Qualify">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    {questionsSteps[postCurrentStep]}
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    // const buildContractInfo = () => {
    //     return (
    //         <div>
    //             <div className={styles.stackedSites}>
    //                 <div className={`${styles.multisiteBlock} ${styles.showMultisite}`}>
    //                     <FormCard step={1} assignPostStep={() => {}}>
    //                         <Field fieldLabel={`Start typing premises  address`}>
    //                             <InputFieldAddressLookup fieldName={`searchAddress`} saveValue={preSaveValueFunct} />
    //                         </Field>

    //                         {currentMultisiteAnswers.addressLineOne ? (
    //                             <>
    //                                 {/* <FieldfieldLabel="Site Address Line 1"></Field>
    //                             <Field fieldLabel="Site Address Line 2"></Field>
    //                             <Field fieldLabel="Town/City"></Field>{" "} */}
    //                                 <InputFieldText
    //                                     key={1 * 1}
    //                                     fieldName={`addressLineOne`}
    //                                     saveValue={preSaveValueFunct}
    //                                     populateValue={currentMultisiteAnswers.addressLineOne}
    //                                 />
    //                                 <br />
    //                                 <InputFieldText
    //                                     key={2 * 1}
    //                                     fieldName={`addressLineTwo`}
    //                                     saveValue={preSaveValueFunct}
    //                                     populateValue={currentMultisiteAnswers.addressLineTwo}
    //                                 />
    //                                 <br />
    //                                 <InputFieldText
    //                                     key={3 * 1}
    //                                     fieldName={`locale`}
    //                                     saveValue={preSaveValueFunct}
    //                                     populateValue={currentMultisiteAnswers.locale}
    //                                 />
    //                                 <br />
    //                                 <InputFieldText
    //                                     key={4 * 1}
    //                                     fieldName={`postcode`}
    //                                     saveValue={preSaveValueFunct}
    //                                     populateValue={currentMultisiteAnswers.postcode}
    //                                 />
    //                                 <br />
    //                             </>
    //                         ) : (
    //                             <></>
    //                         )}
    //                         <Field fieldLabel="What level of authority did the energy broker have?">{OptionButtons("brokerAuthority")}</Field>
    //                         <Field fieldLabel="How did the claimant become engaged by the energy broker? ">{OptionButtons("brokerEngagement")}</Field>

    //                         <SubmitButton text={`Next`} btnType="primary" onClick={() => completeStep()} />
    //                     </FormCard>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    return getCurrentStep();
};
