import styles from "./styles.module.scss";
import pageStyles from "../../../globals/pages.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import { Wrapper } from "../../../../reusable";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../.././../../../assets/fusionSign/logo.png";
import stars from "../.././../../../assets/fusionSign/stars.png";
import profile from "../../../../../assets/profile.png";
import { IsMobile } from "../../../isMobile/isMobile";
import axios from "axios";
import { useParams } from "react-router-dom";

export function ContactFusionHeader() {
    const [clientName, setClientName] = useState<String>();
    const { token } = useParams();
    // useEffect(() => {
    //     axios
    //         .post(`${process.env.REACT_APP_API_USERTOKEN_URL}`, { token })
    //         .then((response) => {
    //             const userData = response.data;
    //             console.log("data:", userData);
    //             setClientName(`${userData.name}`);
    //         })
    //         .catch((e) => {
    //             console.log("error:", e);
    //         });
    // }, []);
    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <Wrapper>
                <div className={`${styles.innerHeader}  ${responsiveStyles.innerHeader} `}>
                    <div className={`${styles.logoContainer}  ${responsiveStyles.logoContainer} `}>
                        <img src={logo} />
                    </div>
                    <div className={`${styles.profileContainer}  ${responsiveStyles.ProfileContainer} `}>
                        <p>{"Hi :)"}</p>
                    </div>
                </div>
            </Wrapper>

            {IsMobile() ? (
                <></>
            ) : (
                <div className={`${styles.headerBorder}  ${responsiveStyles.headerBorder} `}>
                    <ul>
                        <li>Secure SSL encryption</li>
                        <li>
                            <img src={stars} alt="stars" />
                            <span>4.8 / 5</span>
                        </li>
                        <li>No win no fee</li>
                    </ul>
                </div>
            )}
        </header>
    );
}
