import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import { apiErrors } from "../helpers/apiErrors";
import CompleteApplicationSign from "../components/completeApplicationSign";
import HP from "../components/firmSignatures/hp";
import Courmacs from "../components/firmSignatures/courmacs";
import { useUpdateClaimData } from "../claimProvider";
import Probitas from "../components/firmSignatures/probitas";

function BecSign() {
    const [urlData, setUrlData] = useState<any>();
    const { token } = useParams();
    const [collectData, setCollectData] = useState(false);
    const [collectedData, setCollectedData] = useState<any>(null);

    const updateClaim = useUpdateClaimData();
    useEffect(() => {
        if (token) setCollectData(true);
    }, [token]);

    useEffect(() => {
        if (collectData && !collectedData) {
            Loading.circle("Loading Claim Information");
            const endpoint = `${process.env.REACT_APP_BEC_LEAD_URL}`;
            console.log("endpoint : ", endpoint);

            axios
                .post(endpoint, { token })
                .then((response) => {
                    setCollectedData(response.data.sigData);
                    Loading.remove();
                    console.log("response.data.sigData:", response.data.sigData);
                    updateClaim(response.data.sigData);
                })
                .catch((e) => {
                    console.log("error:", e);
                    Loading.remove();
                    if (e.response) {
                        const serverError = e.response.data;
                        apiErrors(serverError);
                    } else {
                        apiErrors("unknown");
                    }
                });
        }
    }, [collectData]);

    const selectFirmContent = (firm: string) => {
        switch (firm) {
            case "HP":
                return <HP />;
                break;
            case "Courmacs":
                return <Courmacs />;
                break;
            case "Probitas":
                return <Probitas />;
                break;
            default:
                break;
        }
        return <p></p>;
    };

    return (
        <div className={`${styles.becContent}`}>
            {!collectedData || collectedData?.notFound ? (
                <>
                    <p>No data available</p>
                </>
            ) : (
                selectFirmContent(collectedData.law_firm)
            )}
        </div>
    );
}

export default BecSign;
