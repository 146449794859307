import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../.././../../../assets/fusionSign/logo.png";
import stars from "../.././../../../assets/fusionSign/stars.png";
import { Wrapper } from "../../../../reusable";
import { IsMobile } from "../../../isMobile/isMobile";

export function ContactFusionFooter() {
    return (
        <>
            {IsMobile() ? (
                <div className={`${styles.headerBorder}  ${responsiveStyles.headerBorder} `}>
                    <ul>
                        <li>Secure SSL encryption</li>
                        <li>
                            <img src={stars} alt="stars" />
                            <span>4.8 / 5</span>
                        </li>
                        <li>No win no fee</li>
                    </ul>
                </div>
            ) : (
                <></>
            )}
            <div className={`${styles.footer} ${responsiveStyles.footer}`}>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    <Wrapper>
                        <div className={`${styles.innerFooterContent} ${responsiveStyles.innerFooterContent}`}>
                            <div className={styles.logoContainer}>
                                <img src={logo} />
                            </div>
                            <div className={styles.disclaimerContainer}>
                                <p className={styles.disclaimer}>
                                    Your Business Energy Refunds is a website and trading name operated by Proaxis Legal Ltd, a Claims Management
                                    Company authorised and regulated by the Financial Conduct Authority, reference number 833988. Proaxis Legal Ltd is
                                    a private limited company, registered in England and Wales under Company number 4494454. VAT number 800564657,
                                    registered address is Portal Business Centre, Bridgewater House, North Road, Ellesmere Port, Cheshire, CH65 1AF.
                                    Proaxis Legal Ltd does not charge customers for their service and in return for passing customer details to a
                                    Solicitor Proaxis Legal Ltd receive a fee for their marketing services. If successful in any claim for
                                    compensation and by way of deduction the solicitor will charge a percentage of any compensation awarded that will
                                    have been discussed and agreed before starting your case. There are no hidden or up-front costs and if any claim
                                    is unsuccessful no charge will be made.
                                </p>
                                <p className={styles.disclaimer}>
                                    Full terms and conditions are outlined and copies are available on request from the following address: Proaxis
                                    Legal Limited, Portal Business Centres, Bridgewater House, North Road, Ellesmere Port, CH65 1AF.
                                </p>
                            </div>
                        </div>
                    </Wrapper>
                </div>
                <div className={`${styles.footerPolicyLinks} ${responsiveStyles.footerPolicyLinks}`}>
                    <Wrapper>
                        <p>
                            <a href="https://claim.myenergyrefunds.co.uk/privacy-policy/" target="__blank">
                                Privacy Policy
                            </a>
                            |
                            <a href="https://claim.myenergyrefunds.co.uk/terms-of-use/" target="__blank">
                                Terms & Conditions
                            </a>
                            |
                            <a href="https://claim.myenergyrefunds.co.uk/complaints-policy/" target="__blank">
                                Cookies Policy
                            </a>
                            |
                            <a href="https://claim.myenergyrefunds.co.uk/contact/" target="__blank">
                                Contact Us
                            </a>
                        </p>
                    </Wrapper>
                </div>
                <div className={`${styles.footerbottom} ${responsiveStyles.footerbottom}`}>
                    <Wrapper>
                        <p>Copyright © {new Date().getFullYear()} Business Energy Refunds. All Rights Reserved.</p>
                    </Wrapper>
                </div>
            </div>
        </>
    );
}
