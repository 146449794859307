import styles from "./styles.module.scss";
import { Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Report } from "notiflix/build/notiflix-report-aio";

import HpSignBanner from "../../sigBanners/HpBanner";
import { useClaimData } from "../../../claimProvider";
import { SignaturePad } from "../../reusable/sigPad";
import { browserName, osName } from "react-device-detect";
import Previewer from "../../reusable/previewer";
import ProbitasSignBanner from "../../sigBanners/ProbitasBanner";

function Probitas() {
    const claimData = useClaimData();

    const completeClaim = () => {
        console.log("documents signed....");
        Report.success(
            "Documents Signed",
            "Thank you for signing all your documents. We will update you on your claim in due course.",
            "Okay",
            () => {
                window.location.href = "https://form.myenergyrefunds.com/claim/start";
            }
        );
    };
    return (
        <div className={`${styles.firmContainer} ${responsiveStyles.firmContainer}  `}>
            <ProbitasSignBanner />
            <Wrapper>
                <div className={`${styles.firmContainerContent} ${responsiveStyles.firmContainerContent}`}>
                    <div className={styles.explainerContent}>
                        <h2>Sign Your Client Care Pack</h2>
                        <p>
                            We have reviewed your business energy claim and have now referred the case to our panel law firm. In order for us to
                            complete the referral and to progress {claimData.company_name}'s' business energy claim against{" "}
                            <b>{claimData.energy_supplier}</b>, you need to sign your client care pack.
                            <br />
                            <br />
                            Based on the information you have previously provided we have prepared your documents, please follow the instructions
                            below to complete the required actions.
                        </p>
                    </div>
                    <div className={styles.previewsContainer}>
                        <h2>Preview Documents</h2>

                        <div className={styles.previews}>
                            <div className={styles.singlePreview}>
                                <div className={styles.innerSinglePreview}>
                                    <Previewer url={claimData.cfaUrl} />
                                </div>
                                <p>
                                    <a target="__blank" href={claimData.cfaUrl}>
                                        Download Preview
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.signatureContainer}>
                        <SignaturePad
                            additionalAction={completeClaim}
                            extraPayload={{ type: "probitasSign", browser: browserName, device: osName }}
                        />
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default Probitas;
